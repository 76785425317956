export function DadosEstruturados() {
  // const dados = {
  //   "@context": "http://schema.org",
  //   "@type": "Organization",
  //   url: "https://agenciacaravela.com.br",
  //   image:
  //     "https://lh3.googleusercontent.com/p/AF1QipPNoI-RGQGPT0-y9odLUgycx-DlO3-jfE9oALLd=s1360-w1360-h1020",
  //   sameAs: [
  //     "https://facebook.com/agcaravela",
  //     "https://instagram.com/agenciacaravela",
  //     "https://www.linkedin.com/company/ag%C3%AAncia-caravela",
  //   ],
  //   name: "Agência Caravela",
  // };

  const dados = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "Organization",
        "@id": "https://agenciacaravela.com.br/#organization",
        "name": "Agência Caravela",
        "url": "https://agenciacaravela.com.br/",
        "sameAs": [
          "https://facebook.com/agcaravela",
          "https://instagram.com/agenciacaravela",
          "https://www.linkedin.com/company/ag%C3%AAncia-caravela"
        ],
        "logo": {
          "@type": "ImageObject",
          "inLanguage": "pt-BR",
          "@id": "https://agenciacaravela.com.br/#/schema/logo/image/",
          "url": "https://agenciacaravela.com.br/fav/apple-touch-icon.png",
          "contentUrl": "https://agenciacaravela.com.br/fav/apple-touch-icon.png",
          "width": 120,
          "height": 120,
          "caption": "Agência Caravela"
        },
        "image": { "@id": "https://agenciacaravela.com.br/#/schema/logo/image/"}
      },
      {
        "@type": "WebSite",
        "@id": "https://agenciacaravela.com.br/#website",
        "url": "https://agenciacaravela.com.br/",
        "name": "Agência Caravela - BR",
        "description": "Agência marketing",
        "publisher": { "@id": "https://agenciacaravela.com.br/#organization"},
        "potentialAction": [
          {
            "@type": "SearchAction",
            "target": {
              "@type": "EntryPoint",
              "urlTemplate":
                "https://agenciacaravela.com.br/?s={search_term_string}"
            },
            "query-input": "required name=search_term_string"
          }
        ],
        "inLanguage": "pt-BR"
      },
      {
        "@type": "ImageObject",
        "inLanguage": "pt-BR",
        "@id": "https://agenciacaravela.com.br/#primaryimage",
        "url": "https://lh3.googleusercontent.com/p/AF1QipPNoI-RGQGPT0-y9odLUgycx-DlO3-jfE9oALLd=s1360-w1360-h1020",
        "contentUrl":
          "https://lh3.googleusercontent.com/p/AF1QipPNoI-RGQGPT0-y9odLUgycx-DlO3-jfE9oALLd=s1360-w1360-h1020",
        "width": 1244,
        "height": 669
      },
      {
        "@type": "Place",
        "address": {
          "@type": "PostalAddress",
          "streetAddress": "R. Rio Grande do Sul, 1182 ",
          "addressLocality": "Poços de Caldas",
          "addressRegion": "MG",
          "postalCode": "37701-001",
          "addressCountry": "Brasil"
        }
      },
      {
        "@type": "WebPage",
        "@id": "https://agenciacaravela.com.br/#webpage",
        "url": "https://agenciacaravela.com.br/",
        "name": "Mar calmo nunca fez bom marinheiro!",
        "isPartOf": { "@id": "https://agenciacaravela.com.br/#website" },
        "about": { "@id": "https://agenciacaravela.com.br/#organization" },
        "primaryImageOfPage": {
          "@id": "https://agenciacaravela.com.br/#primaryimage"
        },
        "description":
          "Algumas agências até tem a coragem para vencer desafios Nós temos o hábito!",
        "breadcrumb": { "@id": "https://agenciacaravela.com.br/#breadcrumb" },
        "inLanguage": "pt-BR",
        "potentialAction": [
          {
            "@type": "ReadAction",
            "target": ["https://agenciacaravela.com.br/"]
          }
        ]
      },
      {
        "@type": "BreadcrumbList",
        "@id": "https://agenciacaravela.com.br/#breadcrumb",
        "itemListElement": [{ "@type": "ListItem", "position": 1, "name": "Home" }]
      }
    ],
    
  };

  //
  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(dados) }}
    />
  );
}
